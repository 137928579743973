@font-face {
    font-family: 'Mohr Rounded';
    src: url('./fonts/MohrRounded-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Mohr Rounded Bold';
    src: url('./fonts/MohrRounded-Bold.ttf') format('truetype');
}

body, *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mohr Rounded', sans-serif;
}

.wrapper {
    width: 100%;
    max-width: 1227px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper.block-wrapper { display: block; }
.wrapper.column-wrapper { flex-direction: column; align-items: center; }
.wrapper.right-wrapper { justify-content: right; height: 100%; padding: 0; flex: 1; }
.wrapper.footer-wrapper {
    display: flex; /* This will make the children elements (left and right content) sit side by side */
    justify-content: space-between; /* This will push the left and right contents to their respective sides */
    align-items: flex-start; /* This will align the top of the children to the top of the wrapper */
}
.mohr-purple { color:#aa33ed; }
.mohr-orange { color:#ff9800; }
.gray-bg { width: 100%; background-color: #f2f2f2; }


.intro-content-box {
    display: flex;
    align-items: center;
    width: 772px;
    justify-content: space-between; 
    flex-wrap: wrap
}

.logo {
    flex: 0;
    margin-right: 20px;
}

.intro {
    flex: 1;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Corrected from flex-end */
}

.intro-content-box-img {
    flex: 0;
    margin-left: 0px;
}

.header {
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    text-align: center;
}
.heading-black {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 38px;
    margin:30px 0px 70px;
}

.heading-green {
    color:#02c971;
    font-size:36px;
    margin: 0px;
    font-family: 'Mohr Rounded Bold', sans-serif;
    margin:30px 0px 20px;
}

.project-wrapper-text {
    flex: 1; /* Takes remaining space */
}
.project-wrapper-text p {
  margin-bottom: 50px;
  max-width: 800px;
}

.project-wrapper {
    display: flex;
    justify-content: space-between; /* Spread the img and text apart */
    align-items: center; /* Align img and text vertically centered */
    margin-bottom: 80px; /* Space between project-wrappers */
}

.project-wrapper-img { margin-right: 50px; }

.project-wrapper-btn {
    background-color: #02c971;
    border:0px;
    padding: 8px 25px 10px;
    color:#fff;
    font-family: 'Mohr Rounded Bold', sans-serif;
    border-radius: 15px;
    letter-spacing: 0.8px;
        margin-left: 20px;
}
.project-wrapper-fa-display {
    font-size: 22px;
}
.project-wrapper-link {
    color:#000;
    text-decoration: none;
    padding-left: 20px;
}
.dev-teams-container,.our-brands-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 70px;
}

.dev-teams-title {
    margin:30px 0px 70px;
    font-family: 'Mohr Rounded Bold', sans-serif;
}
.dev-teams-title span {
    font-size: 16px;
    font-family: 'Mohr Rounded Bold', sans-serif;
}

.dev-team,.our-brand {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;    /* Center items horizontally */
    flex: 1;                /* Each dev-team takes equal space */
    margin: 0 30px;         /* Spacing between each dev-team */
}
.dev-team-image-container,.our-brand-image-container {
    display: flex;
    align-items: center;  /* Vertically center the image */
    height: 93px;         /* Fixed height for the container */
    margin-bottom: 50px;  /* Space below the container */
}

.dev-team-img {
    max-height: 100%;     /* Image will not grow beyond its container */
    width: auto;          /* Width adjusts based on the original aspect ratio */
    align-self: flex-start;
}

.dev-team-text {
    text-align: left;
}

.omission-title {
    display: flex;
    align-items: center; /* vertically align the elements */
    justify-content: center; /* horizontally align the elements */
    width: 100%;
    margin-bottom: 20px; /* Optional: Add some space between the title and the content */
}

.logo {
    margin-right: 20px; /* Optional: Add some space between the logo and the heading */
}


.omission-wrapper {
    width: 100%;
    background: url('./img/omission-bg.jpg') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: flex-end; /* Ensures content is aligned to the right */
    align-items: center; /* Centers the content vertically */
    position: relative;
}
.omission-title-element {
  width: auto;
  margin: 20px 10px;
}
.omission-title-element.omission-logo {
  margin-top: 50px;
}

.right-side-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(108, 223, 254, 0.8);
    flex-basis: 100%; 
    max-width: none;
    height: 100%;
    width: 55%;
}

.wrapper.right-wrapper p {
    color: #000000; /* Optional: If you want white text, given the blue background */
    max-width: 320px;
    padding:10px 20px;
    z-index: 99;
    margin-right: 23%;
}
.our-brand-title {
    font-size: 36px;
    font-family: 'Mohr Rounded Bold', sans-serif;
    margin: 50px 0px;
}
.our-brand {
  align-items: flex-start;
}
.our-brand-image-container {
    height: auto;
}
.brand-name-title {
    font-size: 36px;
    font-family: 'Mohr Rounded Bold', sans-serif;
    margin-bottom: 10px;
}
.left-footer-content,
.right-footer-content {
    display: flex;
    flex-direction: column; /* This will arrange the child elements vertically */
    align-items: flex-start; /* This will left-align the content inside each section */
}

.right-footer-content {
    display: flex;
    justify-content: flex-end; /* This will push the images as far right as possible */
}
.footer-sidenode {
    width: 100%; 
    text-align: center; /* To center the text */
    margin-top: 20px; /* Optional: to provide some spacing from the contents above */
    font-size: 20px;
}
.left-footer-content-adress span, .left-footer-content-contact span {
    display: block;
    margin:10px 85px;
}

.left-footer-content-contact {
    margin-top: 20px;
}
.right-footer-content-image-container img {
    max-height: 50px;
    margin: 20px 0px;
}
.footer-gray-bg {
    padding-bottom:100px;
}

/* minor style fixes */
.intro p {
    margin:20px 0px;
}
