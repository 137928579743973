@media (max-width: 1200px) {
    .wrapper.right-wrapper p {
        margin-right: 17%;
    }
}

@media (max-width: 1000px) {


    .right-side-wrapper {
        width: 100%;
        background: rgba(108, 223, 254, 0.9);
        padding:;
    }
    .wrapper.column-wrapper {
        flex-direction: column;  /* Stack the columns vertically */
        align-items: center;     /* Horizontally center everything */
    }

    .dev-teams-container,.our-brands-container {
        flex-direction: column; /* This will stack the dev-teams vertically */
    }

    .dev-team, .our-brand {
        width: 100%;            /* Takes full width */
        margin:0px 0px 20px;
    }
    .dev-team-text,.our-brand-text {
        max-width: 500px;
        text-align: center;
        margin-bottom: 50px;
    }

    .dev-team-image-container, .dev-team-img, .dev-team-text, .brand-name-title, .our-brand-text {
        align-self: center;    /* Center the image and text containers */
    }
    .dev-team-image-container {
        margin-bottom: 30px;
    }
    .dev-team-image-container, .our-brand-image-container {
        align-self: center;
        height: auto;
        margin-bottom: 20px;
    }


    /* Omission stuff */
    .omission-title {
        flex-direction: column;
        align-items: center; /* center the children horizontally */
    }
    .wrapper.right-wrapper {
        justify-content: center;
    }
    .wrapper.right-wrapper p {
        margin: auto;
        padding:20px;

    }
    .wrapper.footer-wrapper {
        flex-direction: column; /* Stack the left and right content vertically */
        align-items: center; /* Center the child elements horizontally */
    }
    .left-footer-content, .right-footer-content {
        width: 100%; /* Use the full width of the wrapper */
        text-align: center; /* Center the content inside */
        align-items: center; /* Center the child elements horizontally */
        margin-bottom: 20px; /* Optional: for some spacing between the contents */
    }

}

@media (max-width: 768px) {
    .intro-content-box {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .ptd-logo {
        margin: 0 auto;
        display: block;
        max-height: 440px;
        margin-bottom: 20px;
    }

    .intro {
        text-align: center;
        margin-left: 0px;
    }

    .intro h1 {
        font-size: 24px; /* Adjust based on what looks good, this is just an example */
        line-height: 1.4; /* Adjust based on what feels right */
        word-break: break-word; /* This will break long words */
    }

    .intro-content-box-img {
        margin: 0 auto;
        display: block;
    }

    /* projects */
    .project-wrapper {
        flex-direction: column;  /* Stack children vertically */
        align-items: center;     /* Center children horizontally */
        text-align: center;      /* Center the text */
    }

    .project-wrapper-img {
        margin-right: 0;         /* Remove margin since it's now stacked */
        margin-bottom: 20px;     /* Space between image and text */
    }
    .project-wrapper-fa-display {
        margin:20px;
    }

    .project-wrapper-text {
        /* Center text */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}